import React from "react"
import Section from "../../../components/section/Section"

const PuzzlelibSectionSecond = ({ className, data }: { className: string; data: any }) => {
  const { heading, paragraphs } = data.data.content.PuzzlelibSectionSecondConfig

  return (
    <Section className={className}>
      <div className="sova-section-container">
        <h2 className="sova-section-child sova-h2">{heading}</h2>
        {paragraphs.map((paragraph: string, key: number) => (
          <p
            key={key}
            className={`sova-section-child sova-section-paragraph sova-section-child_puzzlelib-paragraph sova-section-child_puzzlelib-2-paragraph-${key} sova-p-tiny sova-common-text-centered`}
          >
            {paragraph}
          </p>
        ))}
      </div>
    </Section>
  )
}

PuzzlelibSectionSecond.displayName = "PuzzlelibSectionSecond"

export default PuzzlelibSectionSecond
