import React from "react"
import Section from "../../../components/section/Section"
import puzzlelibSupportImg from "../../../assets/images/puzzlelib/puzzlelib-support.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  puzzlelibSupportImg: puzzlelibSupportImg,
}

const PuzzlelibSectionThird = ({ className, data }: { className: string; data: any }) => {
  const { heading, preheading, paragraph, list, puzzlelibSupportImgSrc, puzzlelibSupportImgAlt } = data.data.content.PuzzlelibSectionThirdConfig

  return (
    <Section className={className}>
      <div className="sova-section-container sova-section-container_row sova-section-container_puzzlelib-3">
        <div className="sova-section-child-container sova-section-child-container_row sova-section-child-container_puzzlelib-bg sova-section-child-container_puzzlelib-bg-red">
          <div className="sova-section-child-container sova-section-child-container_puzzlelib-3">
            <h3 className="sova-section-child-text sova-section-child-text_puzzlelib sova-section_puzzlelib-preheading sova-h3">{preheading}</h3>
            <p className="sova-section-child sova-section-paragraph sova-section-child-text sova-section-child-text_puzzlelib sova-section-child-text_puzzlelib-3 sova-p-tiny">{paragraph}</p>
            <ul className="sova-section-column sova-section_puzzlelib-list">
              {list.map((listItem: string, key: number) => (
                <li key={key} className="sova-section-child sova-section-child_puzzlelib-listItem sova-p-tiny">
                  {listItem}
                </li>
              ))}
            </ul>
          </div>
          <img src={images[puzzlelibSupportImgSrc]} alt={puzzlelibSupportImgAlt} className="sova-section-child sova-section-img sova-section-child_puzzlelib_img sova-section-child_puzzlelib_img-3" />
        </div>
        <div className="sova-section-child-container sova-section-child-container_puzzlelib-bg sova-section-child-container_puzzlelib-bg-blue">
          <h2 className="sova-section-child sova-section-child_puzzlelib-2-heading sova-h2">{heading}</h2>
        </div>
      </div>
    </Section>
  )
}

PuzzlelibSectionThird.displayName = "PuzzlelibSectionThird"

export default PuzzlelibSectionThird
