import React from "react"
import Section from "../../../components/section/Section"

interface Content {
  paragraph: string
  points: [string]
  btnText: string
  btnLink: string
}

const PuzzlelibSectionFifth = ({ className, data }: { className: string; data: any }) => {
  const { heading, content, link } = data.data.content.PuzzlelibSectionFifthConfig

  return (
    <Section className={className}>
      <div className="sova-section-container">
        <h2 className="sova-section-child  sova-h2">{heading}</h2>
        {content.map(({ paragraph, points, btnText, btnLink }: Content, key: number) => (
          <div className="sova-section-child" key={key}>
            <p className="sova-section-child sova-section-paragraph sova-section-child_puzzlelib-paragraph sova-section-child_puzzlelib-5-paragraph sova-p-tiny">{paragraph}</p>
            <div key={key} className={`sova-section-child-container sova-section-child-container_row sova-section-child-container_puzzlelib-5 sova-section-child-container_puzzlelib-5_${key}`}>
              {points &&
                points.map((point, key) =>
                  point === "BREAK" ? (
                    <div key={key} className="sova-section-child sova-section-child_puzzlelib-5-point-break" />
                  ) : (
                    <span key={key} className={`sova-section-child sova-section-child_puzzlelib-point sova-section-child_puzzlelib-5-point-${key}`}>
                      {point}
                    </span>
                  )
                )}
              <a href={btnLink}>
                <button className="sova-btn sova-btn_secondary sova-btn_small sova-section-child_puzzlelib-point sova-section-child_puzzlelib-btn">{btnText}</button>
              </a>
            </div>
          </div>
        ))}
        {link && (
          <div className="sova-section-child-container_puzzlelib-5 sova-product-link">
            <a target="blank" href={link?.href}>
              {link?.text}
            </a>
          </div>
        )}
      </div>
    </Section>
  )
}

PuzzlelibSectionFifth.displayName = "PuzzlelibSectionFifth"

export default PuzzlelibSectionFifth
