import React from "react"

import PuzzlelibSectionFirst from "./puzzlelibSections/PuzzlelibSectionFirst"
import PuzzlelibSectionSecond from "./puzzlelibSections/PuzzlelibSectionSecond"
import PuzzlelibSectionThird from "./puzzlelibSections/PuzzlelibSectionThird"
import PuzzlelibSectionFourth from "./puzzlelibSections/PuzzlelibSectionFourth"
import PuzzlelibSectionFifth from "./puzzlelibSections/PuzzlelibSectionFifth"

const sections = [
  { component: (key: number, className: string, data: any) => <PuzzlelibSectionFirst data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <PuzzlelibSectionSecond data={data} key={key} className={className} /> },
  { component: (key: number, className: string, data: any) => <PuzzlelibSectionThird data={data} key={key} className={className} /> },
  { component: (key: number, className: string, data: any) => <PuzzlelibSectionFourth data={data} key={key} className={className} /> },
  { component: (key: number, className: string, data: any) => <PuzzlelibSectionFifth data={data} key={key} className={className} /> },
]

const PuzzlelibSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-puzzlelib  sova-section-puzzlelib_${i}`, data))}</>
}

PuzzlelibSections.displayName = "PuzzlelibSections"

export default PuzzlelibSections
