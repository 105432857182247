import React from "react"
import Section from "../../../components/section/Section"
import crossPlatformImg from "../../../assets/images/puzzlelib/cross-platform-illustration-for-puzzlelib-page-sova.ai.svg"
import speedImg from "../../../assets/images/puzzlelib/speed-illustration-for-puzzlelib-page-sova.ai.svg"

interface Imgs {
  [k: string]: string
}

interface Content {
  paragraphs: [string]
  preheading: string
  imgSrc: any
  imgAlt: string
  blockName: string
  list: [string]
}

const images: Imgs = {
  crossPlatformImg: crossPlatformImg,
  speedImg: speedImg,
}

const PuzzlelibSectionFourth = ({ className, data }: { className: string; data: any }) => {
  const { content } = data.data.content.PuzzlelibSectionFourthConfig

  return (
    <Section className={className}>
      <div className="sova-section-container sova-section-container_row">
        {content.map(({ paragraphs, preheading, imgSrc, imgAlt, blockName, list }: Content, key: number) => (
          <div className={`sova-section-child-container sova-section-child-container_puzzlelib-4-bg sova-child-container_puzzlelib-4-bg-${key}`} key={key}>
            {key === 0 && <img src={images[imgSrc]} alt={imgAlt} className={`sova-section-child sova-section-img sova-section-child_puzzlelib_img sova-section-child-img_puzzlelib-4-${key}`} />}
            <div className="sova-section-child-container sova-section-child-container_puzzlelib-4">
              <h3 className="sova-section-child sova-section-child-text sova-section-child-text_puzzlelib sova-section_puzzlelib-preheading sova-h3">{preheading}</h3>
              {paragraphs.map((paragraph, key) => (
                <p
                  key={key}
                  className={`sova-section-child sova-section-paragraph sova-p-tiny sova-common-text-centered sova-section-child-text_puzzlelib sova-section-child-text_puzzlelib-${blockName}-${key}`}
                >
                  {key < 1 && (
                    <img src={images[imgSrc]} alt={imgAlt} className={`sova-section-child sova-section-img sova-section-child_puzzlelib_img sova-section-child-img_puzzlelib-4-${blockName}-hidden`} />
                  )}
                  {paragraph}
                </p>
              ))}
              {list && (
                <ul className="sova-section-column sova-section_puzzlelib-list sova-section-paragraph">
                  {list.map((listItem, key) => (
                    <li key={key} className="sova-section-child sova-section-child_puzzlelib-listItem sova-p-tiny">
                      {listItem}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

PuzzlelibSectionFourth.displayName = "PuzzlelibSectionFourth"

export default PuzzlelibSectionFourth
