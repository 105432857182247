import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../components/SEO/seo"
import { layoutConfigPazzlelib } from "../configs/layout/layoutConfig"
import PuzzlelibSections from "../pagesStructures/puzzlelib/puzzlelibSections"

const Puzzlelib = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiPuzzleLib.content.find((el: any) => {
    return el.language === pageContext.lang
  })
  return (
    <Layout {...layoutConfigPazzlelib} bgCustomClass="puzzleLib">
      <SEO title={pageContent.content.title} />
      <PuzzlelibSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default Puzzlelib
export const query = graphql`
  query Puzzlelib($url: String) {
    strapiPuzzleLib(url: { eq: $url }) {
      content {
        language
        content {
          title
          PuzzlelibSectionFifthConfig {
            heading
            content {
              btnLink
              btnText
              paragraph
              points
            }
          }
          PuzzlelibSectionFirstConfig {
            btnLink
            btnText
            decorativeImgs {
              imgAlt
              imgSrc
            }
            heading
            link
            href
            paragraph
          }
          PuzzlelibSectionFourthConfig {
            content {
              blockName
              imgAlt
              imgSrc
              list
              paragraphs
              preheading
            }
          }
          PuzzlelibSectionSecondConfig {
            heading
            paragraphs
          }
          PuzzlelibSectionThirdConfig {
            extraParagraph
            heading
            list
            paragraph
            preheading
            puzzlelibSupportImgAlt
            puzzlelibSupportImgSrc
          }
        }
      }
    }
  }
`
