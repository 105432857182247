import React from "react"
import Section from "../../../components/section/Section"
import imgCloudSrc from "../../../assets/images/puzzlelib/cloud-img-for-puzzlelib-page-sova.ai.svg"
import imgBrainSrc from "../../../assets/images/puzzlelib/brain-img-for-puzzlelib-page-sova.ai.svg"
import { usePageContext } from "../../../components/pageContext/pageContext"
import { Link } from "gatsby"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  imgCloudSrc: imgCloudSrc,
  imgBrainSrc: imgBrainSrc,
}

const PuzzlelibSectionFirst = ({ className, data }: { className: string; data: any }) => {
  const { heading, paragraph, btnText, btnLink, link, href, decorativeImgs } = data.data.content.PuzzlelibSectionFirstConfig

  const { locale } = usePageContext() as any
  return (
    <Section className={`${className} sova-fullscreen-height`}>
      <h1 className="sova-section-child sova-section-child_puzzlelib-1 sova-h1 sova-h1_centered">{heading}</h1>
      <p className="sova-section-child sova-section-child_puzzlelib-1 sova-h4 sova-common-text-centered">{paragraph}</p>
      <div className="sova-section-child-container sova-section-child-container_row">
        <a href={btnLink} id="sova-puzzleLib-0_getStartedBtn">
          <button className="sova-btn sova-btn_primary sova-btn_big">{btnText}</button>
        </a>
        <Link className="sova-h4 sova-h4_link sova-section-child_puzzlelib-0-link" to={`${locale}${href}`}>
          {link}
        </Link>
      </div>
      <div className="sova-section-container sova-section-container_row sova-section-container_puzzlelib-1">
        {decorativeImgs.map(({ imgSrc, imgAlt }: { imgSrc: any; imgAlt: string }, key: number) => (
          <img src={images[imgSrc]} alt={imgAlt} key={key} className={`sova-section-child sova-section-img sova-section-child_puzzlelib-1-img sova-section-child_puzzlelib-1-img-${key}`} />
        ))}
      </div>
    </Section>
  )
}

PuzzlelibSectionFirst.displayName = "PuzzlelibSectionFirst"

export default PuzzlelibSectionFirst
